import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import AddPaymentAdvisorModal from "../../../components/Modals/AddPaymentAdvisor";
import api from "config/api";
import { AuthContext } from "contexts/auth";

const Pagamento = ({ data }) => {
  const { user } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    const fetchPagamentos = async () => {
      try {
        const response = await api.get(`/payments/assessor/${data.id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const sortedPagamentos = response.data.Pagamentos ? 
          response.data.Pagamentos.sort((a, b) => new Date(b.date) - new Date(a.date)) : [];
        setPagamentos(sortedPagamentos);
      } catch (error) {
        console.error("Erro ao buscar pagamentos:", error);
      }
    };

    fetchPagamentos();
  }, [data.id, user.token, modalOpen]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = pagamentos.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(pagamentos.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }

  const totalPago = pagamentos.reduce((acc, pagamento) => acc + pagamento.value, 0);

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary-subtle">
        <Row>
          <Col xs="12" className="d-flex justify-content-between align-items-center">
            <div className="text-primary p-3">
              <h5 className="text-primary">Histórico de Pagamentos</h5>
            </div>
            <Button color="primary" className="me-3" onClick={toggleModal}>
              Adicionar Pagamento
            </Button>
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Table striped>
          <thead>
            <tr>
              <th>Nº</th>
              <th>Data</th>
              <th>Valor (R$)</th>
              <th>Assessor</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((pagamento, index) => (
              <tr key={pagamento.id}>
                <td>{indexOfFirstItem + index + 1}</td>
                <td>{formatDate(pagamento.date)}</td>
                <td>
                  R$ {pagamento.value.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                </td>
                <td>{data.name}</td>
              </tr>
            ))}
            {currentItems.length < itemsPerPage && (
              Array.from({ length: itemsPerPage - currentItems.length }, (_, index) => (
                <tr key={`empty-${index}`}>
                  <td colSpan="4">&nbsp;</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <div className="mt-3">
          <h6>TOTAL: R$ {totalPago.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</h6>
        </div>
        <Pagination>
          {Array.from({ length: totalPages }, (_, index) => (
            <PaginationItem key={index + 1} active={index + 1 === currentPage}>
              <PaginationLink onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>

      </CardBody>

      <AddPaymentAdvisorModal isOpen={modalOpen} toggle={toggleModal} advisorId={data.id}/>
    </Card>
  );
};

Pagamento.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default Pagamento;
