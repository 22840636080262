import React, {
  useEffect,
  useRef,
  useCallback,
  useContext,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { AuthContext } from "contexts/auth"
import { Badge } from "reactstrap"
import api from "config/api"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const { user } = useContext(AuthContext)
  const ref = useRef()

  const allowedCaptureIds = [
    "eb8238bc-9974-40bb-a3ed-694074565db7",
    "91ea435f-e8d8-4092-9ee0-42d2106c1d93",
  ]

  // Função para verificar se o ID de captação atual está na lista de IDs permitidos
  const isAllowedCaptureId = id => {
    return allowedCaptureIds.includes(id)
  }

  const contagemPendenciass = 10
  const [contagemPendencias, setContagemPendencias] = useState(0)
  const [
    contagemPendenciasDesarquivamento,
    setContagemPendenciasDesarquivamento,
  ] = useState(0)
  const [contagemSaques, setContagemSaques] = useState(0)

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const getContagemPendencias = async () => {
    try {
      const response = await api.get("/influencers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      const influenciadoresPendentes = response.data.filter(
        influenciador =>
          influenciador.request_archiving === true &&
          influenciador.archiving === false
      )

      setContagemPendencias(influenciadoresPendentes.length)
    } catch (error) {
      console.error("Error fetching influencers:", error)
    }
  }
  const getContagemPendenciasDesarquivamento = async () => {
    try {
      const response = await api.get("/influencers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      const influenciadoresPendentes = response.data.filter(
        influenciador =>
          influenciador.request_archiving === true &&
          influenciador.archiving === true
      )

      setContagemPendenciasDesarquivamento(influenciadoresPendentes.length)
    } catch (error) {
      console.error("Error fetching influencers:", error)
    }
  }

  useEffect(() => {
    if (user.type === 1) {
      getContagemPendencias()
      getContagemPendenciasDesarquivamento()
    }
  }, [user.token, user.type])

  const getContagemSolicitações = async () => {
    try {
      const response = await api.get("/payments/request", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      const influenciadoresSaquesSolicitados = response.data.filter(
        influenciador =>
          influenciador.isPaymentSuccess === false &&
          influenciador.isRejected === false
      )

      setContagemSaques(influenciadoresSaquesSolicitados.length)
    } catch (error) {
      console.error("Error fetching influencers:", error)
    }
  }

  useEffect(() => {
    if (user.type === 1) {
      getContagemSolicitações()
    }
  }, [user.token, user.type])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            {user.type === 2 ? (
              <>
                <li>
                  <Link to="/#">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ranking">
                    <i className="mdi mdi-podium-gold"></i>
                    <span>{props.t("Top Influencers")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contatos">
                    <i className="bx bxs-user-detail"></i>
                    <span>{props.t("Meus Influenciadores")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/influencersftds">
                    <i className="bx bx-badge-check"></i>
                    <span>{props.t("Meus FTDs")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/scheduling">
                    <i className="mdi mdi-google-classroom"></i>
                    <span>{props.t("Agendar sala")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/meeting">
                    <i className="mdi mdi-google-classroom"></i>
                    <span>{props.t("Agendar Reunião")}</span>
                  </Link>
                </li> */}
             
                {/* {isAllowedCaptureId(user.userId) ? null : (
                  <li>
                    <Link to="/new-influencers">
                      <i className="fas fa-ad far fa-address-book"></i>
                      <span>{props.t("Novos Influenciadores")}</span>
                    </Link>
                  </li>
                )} */}
                <li>
                  <Link to="/arquivados">
                    <i className="bx bx-archive-in"></i>
                    <span>{props.t("Arquivados")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/tasks-kanban">
                    <i className="bx bx-task"></i>
                    <span>{props.t("Kanban")}</span>
                  </Link>
                </li>
              </>
            ) : null}

            {user.type === 3 ? (
              <>
                <li>
                  <Link to="/#">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ranking">
                    <i className="mdi mdi-podium-gold"></i>
                    <span>{props.t("Top Influencers")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/scheduling">
                    <i className="mdi mdi-google-classroom"></i>
                    <span>{props.t("Agendar sala")}</span>
                  </Link>
                </li>
                
                <li>
                  <Link to="/influencers">
                    <i className="mdi mdi-calendar-multiple-check"></i>
                    <span>{props.t("Influencers")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/arquivados">
                    <i className="bx bx-archive-in"></i>
                    <span>{props.t("Arquivados")}</span>
                  </Link>
                </li>
              </>
            ) : null}

            {user.type === 1 ? (
              <>
                <li>
                  <Link
                    to="/#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="bx bx-home-circle"></i>
                      <span>{props.t("Dashboard")}</span>
                    </span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Dashboard Viraw")}</Link>
                    </li>
                    <li>
                      <Link to="/dashboard-aposta">
                        {props.t("Dashboard Aposta")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard-super">
                        {props.t("Dashboard Super")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    to="/#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="mdi mdi-badge-account"></i>
                      <span>{props.t("Colaboradores")}</span>
                    </span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/colaboradores">
                        {props.t("Colaboradores")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/colaboradores-metricas">{props.t("Metricas")}</Link>
                    </li> */}
                    <li>
                      <Link to="/desativados">{props.t("Desativados")}</Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/form-uploads">
                    <i className="mdi mdi-cloud-upload"></i>
                    <span>{props.t("Enviar Arquivo")}</span>
                  </Link>
                </li> */}
                    <li>
                  <Link to="/ranking">
                    <i className="mdi mdi-podium-gold"></i>
                    <span>{props.t("Top Influencers")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/tracking-codes">
                    <i className="mdi mdi-google-analytics"></i>
                    <span>{props.t("Estatísticas")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/scheduling">
                    <i className="mdi mdi-google-classroom"></i>
                    <span>{props.t("Agendar sala")}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="mdi mdi-currency-usd"></i>
                      {props.t("Pagamentos")}
                    </span>
                 
                  </Link>
                  <ul className="sub-menu">
                      <li>
                        <Link
                          to="/generatepayments"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>{props.t("Geral")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/generatepaymentsday"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>{props.t("Diários")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/generatepaymentsantecipado"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>{props.t("Antecipados")}</span>
                        </Link>
                      </li>
                   
              <li>
                      <Link
                        to="/saques-influencers"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span>{props.t("Saques Solicitados")}</span>
                        {contagemSaques > 0 && (
                          <Badge color="danger" pill>
                            {contagemSaques}
                          </Badge>
                        )}
                      </Link>
                    </li>

                  
           
                  </ul>
                </li>
                <li>
                  <Link
                    to="/#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="mdi mdi-account-reactivate-outline"></i>
                      {props.t("Reativar")}
                    </span>
            
                  </Link>
                  <ul className="sub-menu">
                  <li>
                      <Link to="/primeira-abordagem">{props.t("Primeira abordagem")}</Link>
                    </li>
                    <li>
                      <Link to="/conversa-iniciada">{props.t("Conversa iniciada")}</Link>
                    </li>
                    <li>
                      <Link to="/convite-whats">{props.t("Convites de WhatsApp")}</Link>
                    </li>
                    <li>
                      <Link to="/whats-captados">{props.t("WhatsApp captado")}</Link>
                    </li>
                    <li>
                      <Link to="/whats-respondidos">{props.t("WhatsApp respondidos")}</Link>
                    </li>
                    <li>
                      <Link to="/follow-up">{props.t("Follow Up")}</Link>
                    </li>
                    <li>
                      <Link to="/semsucesso">{props.t("Sem Sucesso")}</Link>
                    </li>
                    <li>
                      <Link to="/arquivados">{props.t("Arquivados")}</Link>
                    </li>
                  
                  </ul>
                </li>
               
                <li>
                  <Link
                    to="/#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="mdi mdi-account-star-outline"></i>
                      {props.t("Influenciadores")}
                    </span>
                 
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/influencers/all">{props.t("Todos")}</Link>
                    </li>
                    <li>
                      <Link to="/influencers">{props.t("Ativos")}</Link>
                    </li>
                 
                  </ul>
                <li>
                  <Link
                    to="#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="mdi mdi-card-account-details-star-outline"></i>
                      {props.t("Assessores")}
                    </span>
            
                  </Link>
                  <ul className="sub-menu">
                  <li>
                      <Link to="/assessores/all">{props.t("Gerais")}</Link>
                    </li>
                  <li>
                      <Link to="/assessores/outros">{props.t("Experts")}</Link>
                    </li>
                  
                  </ul>
                </li>



                  <li>
                  <Link
                    to="/#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="mdi mdi-account-clock"></i>
                      {props.t("Solicitações")}
                    </span>
                    {contagemPendencias > 0 && (
                      <Badge color="danger" pill>
                        {contagemPendencias}
                      </Badge>
                    )}
                  </Link>
                  <ul className="sub-menu">
                  <li>
                      <Link
                        to="/pendentes"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span>{props.t("Solicitações de Arquivamento")}</span>
                        {contagemPendencias > 0 && (
                          <Badge color="danger" pill>
                            {contagemPendencias}
                          </Badge>
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/pendentes/desarquivar"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span>{props.t("Solicitações de Desarquivamento")}</span>
                        {contagemPendenciasDesarquivamento > 0 && (
                          <Badge color="danger" pill>
                            {contagemPendenciasDesarquivamento}
                          </Badge>
                        )}
                      </Link>
                    </li>
                  
                  </ul>
                  {/* <li>
                  <Link
                    to="/financeiro"
                  >
                    <span>
                      <i className="mdi mdi-finance"></i>
                      {props.t("Financeiro")}
                    </span>
            
                  </Link>
                </li> */}
                </li>
                </li>
            
          
                
              </>
            ) : null}

            {user.type === 6 ? (
              <>
                <li>
                  <Link to="/">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/scheduling">
                    <i className="mdi mdi-google-classroom"></i>
                    <span>{props.t("Agendar sala")}</span>
                  </Link>
                </li>
              </>
            ) : null}

            {user.type === 7 ? (
              <>
                <li>
                  <Link to="/">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/scheduling">
                    <i className="mdi mdi-google-classroom"></i>
                    <span>{props.t("Agendar sala")}</span>
                  </Link>
                </li>
              </>
            ) : null}

            {user.type === 4 ? (
              <>
          
                <li>
                  <Link
                    to="/#"
                    className="d-flex justify-content-between align-items-center has-arrow"
                  >
                    <span>
                      <i className="bx bx-home-circle"></i>
                      {props.t("Influenciadores")}
                    </span>
                    
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Dashboard Viraw")}</Link>
                    </li>
                    <li>
                      <Link to="/dashboard-super">{props.t("Dashboard SuperBet")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/estatisticas">
                    <i className="mdi mdi-google-analytics"></i>
                    <span>{props.t("Estatísticas")}</span>
                  </Link>
                </li>
              </>
            ) : null}
            {user.type === 5 ? (
              <>
                <li>
                  <Link to="/#">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/colaborador">
                    <i className="bx bxs-user-detail"></i>
                    <span>{props.t("Meus Influenciadores")}</span>
                  </Link>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
