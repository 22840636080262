//INSERIR TODAS AS ROTAS DO USUÁRIO QUE NÃO PRECISAM ESTAR LOGADO

import React from 'react';
import { Routes, Route } from "react-router-dom";


//Contacts

import ContactList from "../pages/Captacao/Contacts/ContactList/contacts-list";
import Dashboard from "../pages/Captacao/Dashboard-saas";
import Echart from "../pages/Captacao/Charts/EChart";
import Profile from "../pages/Captacao/Contacts/ContactsProfile/contacts-profile";
import Kanban from "../pages/Captacao/Tasks/tasks-kanban";
import NovosInfluencers from "../pages/Captacao/InfluencerList/index"
import Ranking from "../pages/Captacao/Ranking/index"
import Arquivados from "../pages/Captacao/InfluencerListArquivados/index"
import InfluFtds from "../pages/Captacao/InfluFds/index"
import Meeting from "../pages/Captacao/Meeting/index"
import Scheduling from "../pages/Captacao/Scheduling"

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />}  />
      <Route index path="/contatos" element={<ContactList />}  />
      <Route index path="/dashboard" element={<Dashboard />}  />
      <Route index path="/contacts-profile" element={<Profile />}  />
      <Route index path="/e-chart" element={<Echart />}  /> 
      <Route index path="/tasks-kanban" element={<Kanban />}  />
      <Route index path="/new-influencers" element={<NovosInfluencers />} />
      <Route index path="/arquivados" element={<Arquivados />} />
      <Route index path="/influencersftds" element={<InfluFtds />} />
      <Route index path="/ranking" element={<Ranking />} />
      <Route index path="/scheduling" element={<Scheduling />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}
