import React, { useState, useEffect, useRef, useMemo, useContext } from "react"
import Toasr from "../../Ui/Notifications"
import api from "config/api"
import { AuthContext } from "../../../contexts/auth"
import { StateContext } from "contexts/states"
import { formatCPF } from "utils/masks"
import { sortearElemento } from "utils/sortUserGrupos"
import {
  onSubmitInfluencers,
  onSubmitInfluencersEdit,
  fetchInfluencerData,
  FecharInfluencer,
} from "../../../pages/Captacao/Contacts/ContactList/requests"
import Toastr from "../../../components/Ui/Notifications";

import {
  formatAleatoria,
  formatCnpj,
  formatCpf,
  formatPhoneNumber,
  validateEmail,
  formatarParaReal,
} from "../../../utils/masks"

import Table from "../../../components/Table"

import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import { useFormik } from "formik"
import * as Yup from "yup"

const ModalCreateInfluencerCloser = ({ Register }) => {
  const { user } = useContext(AuthContext)
  const { modal, setModal } = useContext(StateContext)

  const [selectedStatus, setSelectedStatus] = useState("")
  const [statusClose, setStatusClose] = useState()
  const [statusList, setStatusList] = useState([])
  const [influencer, setInfluencer] = useState()
  const [nome, setNome] = useState("")
  const [phone, setPhone] = useState("")
  const [contact, setContact] = useState()
  const [selectedToastType, setSelectedToastType] = useState()
  const [methodContact, setMethodContact] = useState("")

  const [influencerDataFullname, setInfluencerDataFullname] = useState("")
  const [profileData, setProfileData] = useState({})
  const [profileDataAvatar, setProfileDataAvatar] = useState("")

  const handleToastTypeChange = event => {
    setSelectedToastType(event.target.value)
  }

  useEffect(() => {
    async function getStatus() {
      await api
        .get("/status", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          let status = []
          response.data.forEach(element => {
            if (element.status === "Fechado") {
              setStatusClose(element.id)
            } else {
              status.push(element)
            }
          })
          setStatusList(status)
        })
        .catch(error => {
          console.error(error)
        })
    }
    getStatus()
  }, [])

  function fetchInfluencer(influencerData) {
    setInfluencerDataFullname(influencerData.fullName)
    setProfileData(influencerData.profileData)
    setProfileDataAvatar(influencerData.profilePicture)
  }

  const toggle = () => {
    setModal(!modal)
    setProfileData(null)
    setProfileDataAvatar(null)
    setInfluencerDataFullname(null)
    setInfluencer(null)
    setSelectedStatus(null)
  }

  /* const handleUsernameInputChange = e => {
    const { name, value } = e.target
    if (e.key === "Enter") {
      fetchInfluencerData(value, fetchInfluencer)
    }
    setContact(prevContact => ({
      ...prevContact,
      [name]: value,
    }))
    setInfluencer(prevContact => ({
      ...prevContact,
      [name]: value,
    }))
  }
 */
  const validationRegister = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: (influencer && influencer.email) || "",
      username: (influencer && influencer.username) || "",
      status: (influencer && influencer.status) || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Por favor, informe um e-mail válido")
        .notRequired("Por favor, informe seu e-mail"),
      username: Yup.string().required("Por favor, informe o nome de usuário"),
      status: Yup.string().required("Por favor, selecione o status"),
    }),


    onSubmit: async (values) => {
      setSelectedStatus(values.status)
      try {
        await api.get(`/influencers/username/${values.username}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }).then((response) => {
          if (response.data ? true : false) {
            const registeredBy = response.data;
            Toasr(
              "warning", 
              `Influencer já cadastrado por ${registeredBy.captacao_influencer?.fullname} e está ${registeredBy.status === 8 && !registeredBy.archiving ? 'ATIVO' : 'INATIVO'}!`
            );
          } else {
            onSubmitInfluencers(
              phone,
              validationRegister.values.email,
              validationRegister.values.username,
              validationRegister.values.status,
              user.userId,
              selectedToastType,
              Register
            ).then(() => {
              Toastr("success", "Influencer cadastrado com sucesso!")
              setTimeout(() => {
                window.location.reload()
              }, 3000)
            })
          }
        })
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  })


  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Criar Influencer
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validationRegister.handleSubmit()
            return false
          }}
        >
          {profileDataAvatar ? (
            <img style={{ borderRadius: "50px" }} src={profileDataAvatar} />
          ) : null}
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">@ do Influenciador</Label>
                <Input
                  name="username"
                  type="text"
                  required
                  placeholder="Digite o @"
                  onChange={validationRegister.handleChange}
                  value={validationRegister.values.username || ""}
                  invalid={
                    validationRegister.touched.username &&
                      validationRegister.errors.username
                      ? true
                      : false
                  }
                />
                {validationRegister.touched.username &&
                  validationRegister.errors.username ? (
                  <FormFeedback type="invalid">
                    {validationRegister.errors.username}
                  </FormFeedback>
                ) : null}
              </div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="form-label">Telefone</Label>
                    <Input
                      name="phone"
                      type="text"
                      placeholder="Digite o Telefone"
                      onChange={e =>
                        setPhone(formatPhoneNumber(e.target.value))
                      }
                      value={phone || ""}
                    />
                  </div>
                </Col>

                <Col md={8}>
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Digite o Email"
                      onChange={validationRegister.handleChange}
                      onBlur={validationRegister.handleBlur}
                      value={validationRegister.values.email || ""}
                      invalid={
                        validationRegister.touched.email &&
                          validationRegister.errors.email
                          ? true
                          : false
                      }
                    />
                    {validationRegister.touched.email &&
                      validationRegister.errors.email ? (
                      <FormFeedback type="invalid">
                        {validationRegister.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>

            <Row>
              <Col xs={6}>
                <div className="mb-3">
                  <Label className="form-label">Status</Label>
                  <Input
                    type="select"
                    name="status"
                    className="form-select"
                    onChange={validationRegister.handleChange}
                    onBlur={validationRegister.handleBlur}
                    value={validationRegister.values.status || ""}
                    invalid={
                      validationRegister.touched.status &&
                        validationRegister.errors.status
                        ? true
                        : false
                    }
                  >
                    <option value="">Selecione um status</option>
                    {statusList.length > 0 &&
                      statusList
                        .filter(option => option.id !== 5) // Filtrar o status "Novo"
                        .map(option => (
                          <option key={option.id} value={option.id}>
                            {option.status}
                          </option>
                        ))}
                  </Input>
                  {validationRegister.touched.status &&
                    validationRegister.errors.status ? (
                    <FormFeedback type="invalid">
                      {validationRegister.errors.status}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>


              <Col xs={6}>
                <div className="mb-3">
                  <Label className="form-label">Método de Contato</Label>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <div className="form-check mb-2">
                        <input
                          type="radio"
                          id="radio1"
                          name="toastType"
                          className="form-check-input"
                          value="Instagram"
                          onChange={e => setSelectedToastType(e.target.value)} // Use o valor do input clicado
                        />
                        <Label className="form-check-label" htmlFor="radio1">
                          Instagram
                        </Label>
                      </div>
                      <div className="form-check mb-2">
                        <input
                          type="radio"
                          id="radio2"
                          name="toastType"
                          className="form-check-input"
                          value="WhatsApp"
                          onChange={e => setSelectedToastType(e.target.value)} // Use o valor do input clicado
                        />
                        <Label className="form-check-label" htmlFor="radio2">
                          WhatsApp
                        </Label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check mb-2">
                        <input
                          type="radio"
                          id="radio3"
                          name="toastType"
                          className="form-check-input"
                          value="Youtube"
                          onChange={handleToastTypeChange}
                        />
                        <Label className="form-check-label" htmlFor="radio3">
                          YouTube
                        </Label>
                      </div>
                      <div className="form-check mb-2">
                        <input
                          type="radio"
                          id="radio4"
                          name="toastType"
                          className="form-check-input"
                          value="Forms"
                          onChange={handleToastTypeChange}
                        />
                        <Label className="form-check-label" htmlFor="radio4">
                          Forms Google
                        </Label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>

          <Row></Row>

          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-user"
                  style={{ marginTop: "5px" }}
                >
                  Cadastrar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalCreateInfluencerCloser