import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import Toastr from "../../Ui/Notifications";
import api from "config/api";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const EditBankDataModal = ({ isOpen, toggle, influencerId }) => {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const { user } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const idInfluencer = influencerId 

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await api.post(`/assessor`, {
            name: name,
            email: email,
            password: password
        }, {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        })
        toggle()
        Toastr("sucess", "Assessor Cadastrado com Sucesso.")
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    } catch (error) {
      Toastr("error", "Falha ao cadastrar assessor.")
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Criar Meta Para o Influenciador</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">

      <Form>
        <FormGroup>
          <Label for="name">Nome</Label>
          <Input
            type="text"
            id="name"
            placeholder="Nome do Assessor"
            value={name}
            onChange={handleChangeName}
          />
        </FormGroup>
      </Form>

      <Form>
        <FormGroup>
          <Label for="email">E-mail de Acesso</Label>
          <Input
            type="email"
            id="email"
            placeholder="E-mail de acesso do Assessor"
            value={email}
            onChange={handleChangeEmail}
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            required
          />
        </FormGroup>
      </Form>

      <Form>
        <FormGroup>
          <Label for="password">Senha</Label>
          <div style={{ position: 'relative' }}>
            <Input
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder="Escolha a senha"
              value={password}
              onChange={handleChangePassword}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </FormGroup>
      </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Fechar</Button>
        <Button color="primary" onClick={handleSubmit}>Salvar</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditBankDataModal;